
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("wordle/app", function(){ return i("../app.ts");});
d("wordle/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("wordle/config/environment", function(){ return i("../config/environment.js");});
d("wordle/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("wordle/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("wordle/initializers/export-application-global", function(){ return i("../initializers/export-application-global.js");});
d("wordle/initializers/install-function-helper-manager", function(){ return i("../initializers/install-function-helper-manager.js");});
d("wordle/initializers/usable-function-manager", function(){ return i("../initializers/usable-function-manager.js");});
d("wordle/router", function(){ return i("../router.ts");});
d("wordle/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("wordle/services/page-title", function(){ return i("../services/page-title.js");});
d("wordle/templates/day", function(){ return i("../templates/day.hbs");});
d("wordle/routes/day", function(){ return i("../routes/day.ts");});
d("wordle/routes/index", function(){ return i("../routes/index.ts");});
d("wordle/templates/application", function(){ return i("../templates/application.hbs");});





if (!runningTests) {
  i("../app").default.create({"name":"wordle","version":"0.0.0+75320397"});
}

