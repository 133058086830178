import a0 from "../helpers/page-title.js";
window.define('wordle/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/game.ts";
window.define('wordle/components/game', function () {
  return a1;
});
import { createTemplateFactory } from "../../externals/@ember/template-factory";
export default createTemplateFactory({
  "id": "W6ii9GBr",
  "block": "[[[1,[28,[35,0],[[30,1,[\"day\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@day\"],[[30,1,[\"day\"]]]],null]],[\"@model\"],false,[\"page-title\",\"game\"]]",
  "moduleName": "wordle/templates/day.hbs",
  "isStrictMode": false
});