import a0 from "../helpers/page-title.js";
window.define('wordle/helpers/page-title', function () {
  return a0;
});
import { createTemplateFactory } from "../../externals/@ember/template-factory";
export default createTemplateFactory({
  "id": "bNYZT8NE",
  "block": "[[[1,[28,[35,0],[\"Wordle\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"mx-auto max-w-4xl grid gap-5\"],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
  "moduleName": "wordle/templates/application.hbs",
  "isStrictMode": false
});